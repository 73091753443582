import { Button } from "@material-ui/core";
import React from "react";

function NoonPayment() {
  const fetchData = () => {
    const bodyDataCard = {
      apiOperation: "INITIATE",
      order: {
        name: "NPTestOrder",
        channel: "web",
        reference: "chat1999",
        // values.chooseType ===
        //   ? "CHAT1999"
        //   : values.chooseType === "verySpeed1799" ||
        //     values.chooseType === "promo399"
        //   ? "VERYSPEED1799"
        //   : values.chooseType === "promo0999"
        //   ? "PROMO0999"
        //   : "SPEED1299",
        amount: 19.99,
        // values.chooseType === "chat1999"
        //   ?
        //   : values.chooseType === "verySpeed1799" ||
        //     values.chooseType === "promo399"
        //   ? 17.99
        //   : values.chooseType === "promo0999"
        //   ? 9.99
        //   : 12.99,
        currency: "SAR",
        category: "pay",
      },
      configuration: {
        paymentAction: "SALE",
        returnUrl: "https://explication-dream.web.app/successResetPassword",
        locale: "ar",
      },
    };

    // console.log("jj: ", jj);
    // console.log("jj: ", JSON.stringify(jj));
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "Key_Live ZHJlYW1zX2xpdmVfY2hhdC5kcmVhbXNfbGl2ZV9jaGF0X0NoZWNrb3V0OmZlZDFiMmY1NmMyYzRiNWNhZjMyODIyYTEzZmY2ZWI5",
      },
      body: JSON.stringify(bodyDataCard),
    };

    fetch("https://api.noonpayments.com/payment/v1/order",{ mode: 'no-cors'}, requestOptions)
      .then((response) => response.json())
      .then(async (data) => {
        console.log("status: ", data.resultCode);
        if (data.resultCode === 0) {
        } else {
        }
      })
      .catch((error) => {
        console.log("error: ", error.message);
      });
  };

  return <Button onClick={fetchData}>NoonPayment</Button>;
}

export default NoonPayment;
