import React, {useState} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Lock from "@material-ui/icons/Lock";
import HelpIcon from "@material-ui/icons/Help";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";
import instance from "../api/api";

// import axios from "axios";
import {useParams} from "react-router";

// const URL_SERVER = "http://localhost:5000/api/v1/users";
// axios.defaults.headers.post["Content-Type"] = "application/json";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
      // direction: "rtl",
    },
  },
  paper: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  avatar: {
    marginBottom: theme.spacing(5),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    top: 5,
    right: 5,
  },
}));

const FormResetPassword = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const location = {
    pathname: "/successResetPassword",
    state: {fromDashboard: true},
  };

  const {token} = useParams();

  const sendNotification = async (e) => {
    setLoading(true);

    e.preventDefault();

    if (password === "") {
      setLoading(false);

      alert("Password cannot be empty");
      return;
    }

    if (password.length < 7) {
      setLoading(false);
      alert("Password is very small");
      return;
    }

    setPassword(password);
    try {
      await instance.post(`/users/reset-password-controller`, {
        token,
        newPassword: password,
      });
      setPassword("");
      setLoading(false);
      history.replace(location);
    } catch (error) {
      setLoading(false);
      console.clear();
      console.error("Sorry .. you can't access this page");
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Lock />
        </Avatar>
        <Typography component="h1" variant="h5">
          إعادة ضبط كلمة المرور
        </Typography>

        {loading && <CircularProgress />}
        <form
          className={classes.form}
          noValidate
          onSubmit={sendNotification}
          id="formR"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            style={{direction: "rtl"}}
            label="كلمة المرور"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="password"
            autoFocus
          />

          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                إرسال
              </Button>
            </Grid>
            <Grid item xs={1} style={{alignSelf: "center"}}>
              <Tooltip title="يجب أن تكون كلمة المرور أكثر من ٦ أحرف أو أرقام">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default FormResetPassword;
