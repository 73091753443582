import React, { useEffect, useState, useContext } from "react";
import { Context } from "../context/ContextProvider";
import useChat from "../helpers/useChat";

import Container from "@material-ui/core/Container";
import { useParams } from "react-router-dom";
import instance from "../api/api";
import CssBaseline from "@material-ui/core/CssBaseline";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { SendRounded } from "@material-ui/icons";

const OneQuestion = () => {
  const { _id } = useParams();
  const [isWrite, setIsisWrite] = useState(false);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(null);
  const {
    comments,
    setComments,
    data,
    setData,
    isTyping,
    displayNameUser,
    setDisplayNameUser,
    user,
    setUser,
    // isShowDream,
    // setIsShowDream,
  } = useContext(Context);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { sendMessage, typingUser } = useChat(_id);
  const history = useHistory();

  const consultantsList = ["نور", "تونس", "الجزائر", "حمزة", "هالة"];

  const [values, setValues] = React.useState({
    // ps: "",
    // email: "",
    // title: "",
    // selectSex: "",
    // socialState: "",
    // workingCase: "",
    // age: "",
    // active: true,
    consultants: "",
  });

  useEffect(() => {
    // console.log(
    //   "values effect: ",
    //   values && values.consultants
    //   // data && data.consultants[0]
    // );
    if (
      values &&
      values.consultants.length > 0 &&
      values.consultants !== data &&
      data.consultants[0]
    ) {
      instance
        .patch("/questions/update-question-to-admin", {
          itemNo: `${data.itemNo}`,
          consultant_id: `${
            values.consultants === "الجزائر"
              ? "610fa0bd91eec52af05f846b"
              : values.consultants === "نور"
              ? "6251a99a97c725283f47916e"
              : values.consultants === "تونس"
              ? "6126c93a1488fd69907bbbb2"
              : values.consultants === "هالة"
              ? "6126c9aa1488fd2e0f7bbbb4"
              : "المفسر الرئيسي"
            // ? "6126c8aca12c8781af699629"
            // : values.consultants === "حمزة"
            // : "المفسر الرئيسي"
          }`,
        })
        .then(() => console.log("done ..."))
        .catch((e) => console.log("error: ", e.message));
    }
  }, [values.consultants]);

  const handleChange = (prop) => (event) => {
    // console.log("token: ", token);
    setValues({ ...values, [prop]: event.target.value });
    // console.log("values: ", values.consultants);
  };

  const sendPushNotification = (expoTokenData, itemNo) => {
    // console.log("expo token: ", expoTokenData.data, itemNo);
    if (expoTokenData["type"] === "android")
      instance
        .post("/push/push-android", {
          expoTokenData: expoTokenData.data,
          message:
            data.isRTL === "true"
              ? `\ud83d\udd14 تم الرد على رؤياك، رقم الطلب: ${itemNo}`
              : `\ud83d\udd14 Your dream has been answered, item no:${itemNo}`,
        })
        .catch((e) => console.log("error: ", e.message));
    else {
      // console.log("expo ", expoTokenData);
      instance
        .post("/push/push-apns", {
          deviceToken: expoTokenData.data,
          body:
            data.isRTL === "true"
              ? `\ud83d\udd14 تم الرد على رؤياك، رقم الطلب: ${itemNo}`
              : `\ud83d\udd14 Your dream has been answered, item no:${itemNo}`,
        })
        .catch((e) => console.log("error: ", e.message));
    }
  };
  // const [isOpenChat, setIsOpenChat] = useState(false);

  // const openChat = () => {
  //   if (isOpenChat === false) {
  //     instance
  //       .patch(`/questions/update-one-question-show-chat/${_id}`, {
  //         showChat: true,
  //       })
  //       .then(() => setIsOpenChat(true))
  //       .catch((e) => console.log("error: ", e.message));
  //   } else {
  //     instance
  //       .patch(`/questions/update-one-question-show-chat/${_id}`, {
  //         showChat: false,
  //       })
  //       .then(() => setIsOpenChat(false))
  //       .catch((e) => console.log("error: ", e.message));
  //   }
  // };
  // const showDream = () => {
  //   if (isShowDream === false) {
  //     instance
  //       .patch(`/questions/update-question-reading/${_id}`, {
  //         isReading: true,
  //         counterReadComments: 1,
  //       })
  //       .then(() => setIsShowDream(true))
  //       .catch((e) => console.log("error: ", e.message));
  //   } else {
  //     instance
  //       .patch(`/questions/update-question-reading/${_id}`, {
  //         isReading: false,
  //         counterReadComments: 1,
  //       })
  //       .then(() => setIsShowDream(false))
  //       .catch((e) => console.log("error: ", e.message));
  //   }
  // };
  const [showDream, setShowDream] = useState(true);
  const showDreamNew = () => {
    instance
      .patch(`/questions/update-question-reading/${_id}`, {
        isReading: false,
        counterReadComments: 1,
      })
      .then(() => handleClose() & setShowDream(false))
      .catch((e) => console.log("error: ", e.message));
  };
  const removeOrder = () => {
    instance
      .delete(`/questions/deleted-one-question-for-admin/${_id}`)
      .then(() => handleClose() & history.push("/orders"))
      .catch((error) => console.log("error: ", error.message));
  };
  const showitemNo = (value) => {
    let textItemNo = `${data.itemNo ? data.itemNo : "0000000"}`;
    navigator.clipboard.writeText(textItemNo);
    handleClose();
  };

  // const getQuestions = () => {
  //   instance
  //     .delete(`/questions/deleted-one-question-for-admin/${_id}`)
  //     .then(() => history.push("/orders"))
  //     .catch((error) => console.log("error: ", error.message));
  // };

  const createComment = async () => {
    try {
      if (isWrite && text.length > 0) {
        await instance
          .post(`/questions/create-one-comment/${_id}`, {
            content: text,
          })
          .then(({ data }) => {
            sendMessage(data);
          });
        // setComments((prev) => [...comments, {...prev, content: text}]);

        // console.log("expo: ", data.expoTokenData.data);
        sendPushNotification(data.expoTokenData, data.itemNo); // open this after finish
        await instance
          .patch(`/questions/update-question-reading/${_id}`, {
            isReading: true,
            counterReadComments: 0,
          })
          .then(() => {
            setIsisWrite(false);
          });
        // setIsisWrite(false); // delete in finish
      }
    } catch (error) {
      console.log("error: ", error.message);
    }
  };

  useEffect(() => {
    const fetchAllMessagePush = async () => {
      try {
        if (_id) {
          const getOneQuestion = await instance.get(
            `/questions/question-one/${_id}`
          );
          const getAllCommentOneQuestion = await instance.get(
            `/questions/get-comments-one-question/${_id}`
          );

          const { data } = getOneQuestion;
          const comment = getAllCommentOneQuestion;
          // console.log("c: ", data.expoTokenData.data);
          setData(data);
          setComments(comment.data);
          setIsLoading(false);
          // console.log("question: ", data._id);

          await instance
            .get(`/users/one-user/${data.user_id}`)
            .then(({ data }) => {
              setDisplayNameUser(data.displayName);
            });
        } else {
          // console.log("here ...");
        }

        // console.log("l: ", data.allQuestionsNotToday[0].expoTokenData.data);
        // console.log("l: ", fieldsToday[0].expoTokenData.data);
      } catch (error) {
        //   console.error("Sorry .. ", error.response.status);
        setError(error.message);
        // console.clear();
      }
    };
    fetchAllMessagePush();
  }, [_id]);

  const [text, setText] = useState("");
  // var newSocialState = data.socialState;

  useEffect(() => {
    var decoded = jwt_decode(localStorage.getItem("Token"));
    // console.log("user: ", decoded.user);
    const user = decoded.user;
    setUser(user);
    // console.log("data: ", user, data.user_id);
  }, []);

  const [chooseTypeChange, setChooseTypeChange] = useState("");

  const SendButton = () => (
    <IconButton>
      <SendRounded style={{ color: "#5b3a70", transform: "rotate(180deg)" }} />
    </IconButton>
  );

  if (isLoading) {
    return (
      <Container
        style={{ display: "flex", justifyContent: "center", marginTop: 250 }}
      >
        <CircularProgress style={{ color: "#5b3a70" }} />
      </Container>
    );
  }
  return (
    <Container>
      <CssBaseline />
      {/* <Button
        style={{
          color: "#5b3a70",
          fontSize: 15,
          //   marginTop: 5,
          // marginBottom: 5,
          padding: 15,
        }}
        variant="outlined"
        fullWidth
        onClick={() => history.push(`/list`)}
      >
        {"لوحة التحكم"}
      </Button> */}
      <Button
        style={{
          color: "#5b3a70",
          fontSize: 15,
          marginTop: 5,
          marginBottom: 5,
          padding: 15,
        }}
        variant="outlined"
        fullWidth
        onClick={() => history.push(`/orders`)}
      >
        {"طلبات العملاء"}
      </Button>
      {/* <Button
        style={{
          color: "#5b3a70",
          fontSize: 15,
          marginTop: 5,
          marginBottom: 5,
          padding: 15,
        }}
        variant="outlined"
        fullWidth
        onClick={() => history.push(`/get-question-for-user/${data.user_id}`)}
      >
        {"طلبات العميل"}
      </Button> */}
      {error.length === 0 ? (
        <>
          {/* <Typography
            style={{ marginTop: 20, marginBottom: 10, color: "#5b3a70" }}
          >
            {`تفاصيل الطلب #${data.itemNo ? data.itemNo : "0000000"}`}
          </Typography> */}

          {/* <Button
            style={{
              color: "#5b3a70",
              fontSize: 15,
              marginTop: 0,
              marginBottom: 5,
              padding: 15,
            }}
            variant="outlined"
            fullWidth
            onClick={() =>
              alert(`رقم الطلب: ${data.itemNo ? data.itemNo : "0000000"}`)
            }
          >
            {data.itemNo ? data.itemNo : "0000000"}
          </Button> */}

          <ButtonGroup
            variant="outlined"
            aria-label="contained primary button group"
            fullWidth
          >
            <Button
              style={{
                fontSize: 15,
                // fontSize: 10,
                color: "#5b3a70",
                padding: 15,
              }}
              variant="outlined"
              fullWidth
              onClick={() => {
                setShowDialog(3);
                handleClickOpen();
                // textItemNo = `3333`;
                // navigator.clipboard.writeText(text).then(
                //   function () {
                //     console.log("Async: Copying to clipboard was successful!");
                //   },
                //   function (err) {
                //     console.error("Async: Could not copy text: ", err);
                //   }
                // );
                // alert(`رقم الطلب: ${data.itemNo ? data.itemNo : "0000000"}`);
                // var answer = window.confirm(" حفظ رقم الطلب؟").then(() => {
                //   var text = "Example text to appear on clipboard";
                //   navigator.clipboard.writeText(text).then(
                //     function () {
                //       console.log(
                //         "Async: Copying to clipboard was successful!"
                //       );
                //     },
                //     function (err) {
                //       console.error("Async: Could not copy text: ", err);
                //     }
                //   );
                // });
                // console.log("na:", answer);
                // if (answer) {
                //   //some code
                //   // var text = "Example text to appear on clipboard";
                //   // navigator.clipboard.writeText(text).then(
                //   //   function () {
                //   //     console.log(
                //   //       "Async: Copying to clipboard was successful!"
                //   //     );
                //   //   },
                //   //   function (err) {
                //   //     console.error("Async: Could not copy text: ", err);
                //   //   }
                //   // );
                // } else {
                //   //some code
                //   console.log("لا");
                // }
              }}
            >
              رقم الطلب
            </Button>
            <Button
              style={{
                fontSize: 15,
                color: "#5b3a70",
                padding: 15,
              }}
              onClick={async () => {
                // alert(data.chooseType);
                if (data.chooseType !== "chat1999") {
                  try {
                    await instance
                      .patch(
                        `/questions/update-question-choosetype/${data._id}`,
                        {
                          chooseType: "chat6",
                        }
                      )
                      .then(({ data }) => {
                        // console.log("usre: ", data);
                        alert("تم");
                        setChooseTypeChange(
                          data.chooseType === "chat1999" ? "شات مباشر" : ""
                        );
                        // window.location.reload();

                        // setDisplayNameUser(data.displayName);
                      });
                  } catch (error) {
                    console.log("error:", error.message);
                  }
                }
              }}
            >
              {chooseTypeChange.length > 0
                ? chooseTypeChange
                : // data.chooseType === "regular799"
                //   ? "طلب عادي"
                data.chooseType === "chat1999"
                ? "شات"
                : "اضغط هنا"}
            </Button>
            <Button
              style={{
                color: "#5b3a70",
                padding: 15,
                fontSize: 15,
              }}
              variant="outlined"
              fullWidth
              onClick={() =>
                history.push(`/get-question-for-user/${data.user_id}`)
              }
            >
              {"طلبات"}
            </Button>
            {/* <Button
              style={{
                color: "#5b3a70",
                padding: 15,
              }}
            >
              {data.age}
            </Button> */}

            {/* <Button
              style={{
                color: "#5b3a70",
                padding: 15,
              }}
            >
              {data.selectSex}
            </Button> */}
            {/* <Button
              style={{
                color: "#5b3a70",
                padding: 15,
              }}
            >
              {typeof data.socialState === "string"
                ? data.socialState.startsWith("أ")
                  ? data.socialState.slice(0, 4)
                  : data.socialState.slice(0, 5)
                : "Loading"}
            </Button> */}
            {/* <Button
              style={{
                color: "#5b3a70",
                fontSize: 10,
                padding: 15,
              }}
            >
              {typeof data.workingCase === "string"
                ? data.workingCase.slice(0, 8)
                : "Loading"}
            </Button> */}
            <Button
              style={{
                color: "#5b3a70",
                fontSize: 15,
                // marginTop: 5,
                padding: 15,
              }}
              variant="outlined"
              fullWidth
              onClick={() => setShowDialog(1) & handleClickOpen()}
            >
              {"حذف"}
            </Button>
          </ButtonGroup>
          <TextField
            // id="outlined-select-currency"
            select
            label={`المفسر: ${
              data.consultants.length > 2
                ? "لم يحدد المفسر"
                : data.consultants[0] === "610fa0bd91eec52af05f846b"
                ? "الجزائر"
                : data.consultants[0] === "6251a99a97c725283f47916e"
                ? "نور"
                : data.consultants[0] === "6126c93a1488fd69907bbbb2"
                ? "تونس"
                : data.consultants[0] === "6126c9aa1488fd2e0f7bbbb4"
                ? "هالة"
                : data.consultants[0] === "6126c8aca12c8781af699629"
                ? "حمزة"
                : "المفسر"
            }`}
            // name="socialState"
            onChange={handleChange("consultants")}
            fullWidth
            variant="outlined"
            margin="none"
            style={{
              color: "#5b3a70",
              fontSize: 15,
              marginTop: 5,
            }}
            // required
            value={values.consultants}
          >
            {consultantsList.map((option, key) => (
              <MenuItem key={key} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          {data.isReading === true && showDream === true ? (
            <Button
              style={{
                color: "#5b3a70",
                fontSize: 15,
                marginTop: 5,
                padding: 15,
              }}
              variant="outlined"
              fullWidth
              // onClick={() => showDreamNew()}
              onClick={() => setShowDialog(2) & handleClickOpen()}
            >
              إظهار الحلم{" "}
            </Button>
          ) : null}

          {/* <Button
            style={{
              color: "#5b3a70",
              fontSize: 15,
              marginTop: 5,
            }}
            variant="outlined"
            fullWidth
            onClick={() => openChat()}
          >
            {isOpenChat ? "إغلاق الشات" : "فتح الشات"}
          </Button> */}
          {/* <Button
            style={{
              color: "#5b3a70",
              fontSize: 15,
              marginTop: 5,
            }}
            variant="outlined"
            fullWidth
            // onClick={() => openChat()}
          >
            المفسر:{" "}
            {data.consultants[0] === "610fa0bd91eec52af05f846b"
              ? "ابيب"
              : data.consultants[0] === "6251a99a97c725283f47916e"
              ? "نور"
              : data.consultants[0] === "6126c93a1488fd69907bbbb2"
              ? "يونس"
              : data.consultants[0] === "6126c9aa1488fd2e0f7bbbb4"
              ? "هالي"
              : "المفسر الرئيسي"}
          </Button> */}

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            // maxWidth={true}
          >
            {/* <DialogTitle id="alert-dialog-title">{"تنبيه مهم"}</DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {showDialog === 1
                  ? "هل تريد حذف الطلب؟"
                  : showDialog === 2
                  ? "هل تريد إظهار الحلم للمفسر؟"
                  : "هل تريد نسخ رقم الطلب؟"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  showDialog === 1
                    ? removeOrder()
                    : showDialog === 2
                    ? showDreamNew()
                    : showitemNo(data.itemNo)
                }
              >
                {showDialog === 3 ? "نسخ" : "نعم"}
              </Button>
              <Button onClick={handleClose}>لا</Button>
            </DialogActions>
          </Dialog>

          <Box
            mt={1}
            mb={2}
            style={{
              backgroundColor: "#5b3a70",
              color: "#ffffff",
              padding: 5,
              borderRadius: 3,
            }}
          >
            <Typography variant="subtitle1">{data.title}</Typography>
          </Box>
          <Typography
            style={{ marginTop: 5, marginBottom: 0, color: "#5b3a70" }}
          >
            الشات:
          </Typography>
          {comments.length > 0
            ? comments.map((comment, index) => {
                return (
                  <div key={index}>
                    <Box display="flex">
                      <Box
                        width={data.user_id !== comment.user_id ? "0%" : "20%"}
                      >
                        {" "}
                      </Box>
                      <Box
                        mb={1}
                        style={{
                          backgroundColor:
                            data.user_id === comment.user_id
                              ? "#0066FF"
                              : "#acb1b6",
                          color:
                            data.user_id === comment.user_id
                              ? "#ffffff"
                              : "#424949",
                          borderRadius: 3,
                          padding: 5,
                        }}
                        width="80%"
                      >
                        <Typography variant="subtitle2">
                          {comment.content}
                        </Typography>
                        <Typography
                          // variant="caption"
                          style={{
                            fontSize: 10,
                            marginTop: 5,
                            textAlign: "left",
                            color:
                              data.user_id === comment.user_id
                                ? "#ffffff"
                                : "#424949",
                          }}
                        >
                          {`المرسل: ${
                            data.user_id === comment.user_id
                              ? displayNameUser
                              : "إدارة التطبيق"
                          } - الوقت:${new Date(
                            comment.created_at
                          ).getHours()}:${new Date(
                            comment.created_at
                          ).getMinutes()}`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                );
              })
            : null}

          <Typography variant="subtitle1">
            {isTyping
              ? `${
                  user._id === data.user_id ? "Admin" : displayNameUser
                } يكتب الآن`
              : null}
          </Typography>
          <div
            style={{
              whiteSpace: "nowrap",
              //   textAlign: "center",
              width: "100%",
            }}
          >
            <TextField
              placeholder="اكتب هنا ..."
              multiline
              id="text"
              type="text"
              mb={10}
              size="medium"
              fullWidth
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  typingUser(e.target.value);
                } else {
                  typingUser("");
                }
                setText(e.target.value);
              }}
              value={text}
              InputProps={{
                endAdornment: <SendButton />,
              }}
              onClick={() => {
                setIsisWrite(true);
                createComment();
                setText("");
                typingUser("");
              }}
            />
          </div>
        </>
      ) : (
        <>
          <Typography>
            This is page not found ...
            <Button variant="text" color="primary" size="small" href="/">
              Back
            </Button>
          </Typography>
        </>
      )}
    </Container>
  );
};

export default OneQuestion;
