import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import instance from "../api/api";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  // Badge,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#5b3a70",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 300,
  },
}));

const OrdersForUser = () => {
  const { user_id } = useParams();
  //   React.useEffect(() => {
  //     console.log("user id: ", user_id);
  //   }, []);
  const [fieldsToday, setFieldsToday] = useState([]);
  // const [fieldsNotToday, setFieldsNotToday] = useState([]);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [countQuestions, setCountQuestions] = useState(0);
  const history = useHistory();

  const fetchAllQuestions = async () => {
    try {
      const { data } = await instance.get(
        `/questions/get-for-admin/${user_id}`
      );

      // setFieldsToday(data.allQuestionsToday);
      // setFieldsNotToday(data.allQuestionsNotToday);
      setIsLoading(false);
      // console.log(
      //   "count questions: ",
      //   data.allQuestionsToday.comments.find((c) => c.user_id.length>1)
      // );
      // || cat.user_id !=="6251a99a97c725283f47916e" 6126c93a1488fd69907bbbb2
      let beautyProducts = data?.allQuestions;

      console.log(
        "count questions: ",
        // history.push("/orders")
        beautyProducts
      );

      setFieldsToday(beautyProducts);

      // console.log("count questions: ", fieldsNotToday.length);
      // setCountQuestions(fieldsToday.length);

      // console.log("l: ", data.allQuestionsNotToday[0].expoTokenData.data);
      // console.log("l: ", fieldsToday[0].expoTokenData.data);
    } catch (error) {
      //   console.error("Sorry .. ", error.response.status);
      setError(error.message);
      // console.clear();
    }
  };

  useEffect(() => {
    fetchAllQuestions();
  },[]);
  const classes = useStyles();

  if (isLoading) {
    return (
      <Container
        style={{ display: "flex", justifyContent: "center", marginTop: 250 }}
      >
        <CircularProgress style={{ color: "#5b3a70" }} />
      </Container>
    );
  }
  return (
    <Container>
      <CssBaseline />
      <Button
        style={{
          color: "#5b3a70",
          fontSize: 15,
          //   marginTop: 5,
          // marginBottom: 5,
          padding: 15,
        }}
        variant="outlined"
        fullWidth
        onClick={() => history.push(`/list`)}
      >
        {"لوحة التحكم"}
      </Button>
      {error.length === 0 ? (
        <>
          <Box mt={1} mb={2}>
            <Typography variant="subtitle1">طلبات العميل:</Typography>
          </Box>
          <div className={classes.table}>
            <Paper className={classes.root}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>رقم الطلب</StyledTableCell>
                    {/* <StyledTableCell align="left">Token</StyledTableCell> */}
                    <StyledTableCell align="right">
                      تفاصيل الحلم
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fieldsToday.map((row, id) => (
                    <StyledTableRow key={id}>
                      <StyledTableCell component="th" scope="row">
                        {row.itemNo}
                        {/* {row.title.substring(0, 100)} */}
                      </StyledTableCell>

                      {/* <StyledTableCell align="left">
                        {row.expoTokenData != null
                          ? row.expoTokenData.data
                          : "Not Token"}
                      </StyledTableCell> */}

                      <StyledTableCell align="right">
                        <Button
                          href={`/onequestion/${row._id}`}
                          // onClick={() =>
                          //   console.log("click here ...", fieldsToday)
                          // }
                          // backgroundColor={"red"}
                          style={{
                            backgroundColor: !row.isReading
                              ? "#5b3a70"
                              : "gray",
                            color: "#ffffff",
                          }}
                          disabled={false}
                        >
                          تفاصيل الحلم
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </div>
        </>
      ) : (
        <>
          <Typography>
            This is page not found ...
            <Button variant="text" color="primary" size="small" href="/">
              Back
            </Button>
          </Typography>
        </>
      )}
    </Container>
  );
};

export default OrdersForUser;
