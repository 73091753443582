import React, { useState, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import Lock from "@material-ui/icons/Lock";
// import HelpIcon from "@material-ui/icons/Help";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Tooltip from "@material-ui/core/Tooltip";
// import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import instance from "../api/api";
import MenuItem from "@material-ui/core/MenuItem";
// import axios from "axios";
// import { useParams } from "react-router";
import { Edit } from "@material-ui/icons";
import { Context } from "../context/ContextProvider";
// import axios from "axios";

// const URL_SERVER = "http://localhost:5000/api/v1/users";
// axios.defaults.headers.post["Content-Type"] = "application/json";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
      // direction: "rtl",
    },
  },
  paper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  avatar: {
    marginBottom: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    top: 5,
    right: 5,
  },
}));

const AddDream = ({ t }) => {
  //   const [password, setPassword] = useState("");
  //   const [selectSex, setSelectSex] = useState("نوع الجنس");
  //   const [socialState, setSocialState] = useState("اختر الحالة الإجتماعية");
  //   const [workingCase, setWorkingCase] = useState("");
  //   const [age, setAge] = useState("");
  //   const [chooseType, setChooseType] = useState("");
  //   const [currency, setCurrency] = React.useState("EUR");

  //   const handleChange = (event) => {
  //     event.target.name === "sex" && setSelectSex(event.target.value);
  //     event.target.name === "socialState" && setSocialState(event.target.value);
  //     //   console.log("event: ", selectSex);
  //     return;
  //   };

  const { expoTokenAdmin } = useContext(Context);

  const sendPushNotification = (expoAdminValue, itemNo) => {
    // console.log("expoAdminValue: ", expoAdminValue,itemNo);
    if (expoAdminValue["type"] === "android")
      instance
        .post("/push/push-android", {
          expoTokenData: expoAdminValue["data"],
          message: `${t(`bodyMessage`)} - ${t(`itemNoArabic`)}:${itemNo}`,
        })
        .catch((e) => console.log("error: ", e.message));
    else {
      instance
        .post("/push/push-apns", {
          deviceToken: expoAdminValue["data"],
          body: `${t(`bodyMessage`)} - ${t(`itemNoArabic`)}:${itemNo}`,
        })
        .then(() => console.log("done here"))
        .catch((e) => console.log("error: ", e.message));
    }
  };

  const sendPushNotificationToAdmin = (itemNo) => {
    // console.log("expoTokenAdmin here ..: ", itemNo);
    // console.log("expoTokenAdmin here ..: ", expoTokenAdmin);

    expoTokenAdmin.forEach((expoAdmin) => {
      // if (Platform.os == "ios") {
      // console.log("expoToken: data ... ", expoAdmin.data);
      // console.log("expoToken: type ... ", expoAdmin.type);
      // }
      // console.log("expoAdmin", typeof expoAdmin._id);
      // if (expoAdmin != null) {
      //   sendPushNotification(expoAdmin, itemNo);
      //   // console.log("send to admin ...", expoAdmin);
      // }
      if (
        (expoAdmin != null &&
          dateCurrentAdmin[0] >= 0 &&
          dateCurrentAdmin[0] < 8 &&
          expoTokenAdmin[2] === expoAdmin) ||
        (expoAdmin != null &&
          dateCurrentAdmin[0] >= 0 &&
          dateCurrentAdmin[0] < 8 &&
          expoTokenAdmin[0] === expoAdmin) ||
        (expoAdmin != null &&
          dateCurrentAdmin[0] >= 0 &&
          dateCurrentAdmin[0] < 8 &&
          expoTokenAdmin[7] === expoAdmin)
      ) {
        sendPushNotification(expoAdmin, itemNo);
        // console.log(" here ... ", 1,itemNo);
      } else if (
        // (expoAdmin != null &&
        //   dateCurrentAdmin[0] >= 8 &&
        //   dateCurrentAdmin[0] < 16 &&
        //   expoTokenAdmin[4] == expoAdmin) ||
        (expoAdmin != null &&
          dateCurrentAdmin[0] >= 8 &&
          dateCurrentAdmin[0] < 16 &&
          expoTokenAdmin[3] === expoAdmin) ||
        (expoAdmin != null &&
          dateCurrentAdmin[0] >= 8 &&
          dateCurrentAdmin[0] < 16 &&
          expoTokenAdmin[7] === expoAdmin)
      ) {
        sendPushNotification(expoAdmin, itemNo);
        // console.log(" here ... ", 2, expoAdmin);
      } else if (
        (expoAdmin != null &&
          dateCurrentAdmin[0] >= 16 &&
          dateCurrentAdmin[0] <= 23 &&
          expoTokenAdmin[6] === expoAdmin) ||
        // (expoAdmin != null &&
        //   dateCurrentAdmin[0] >= 16 &&
        //   dateCurrentAdmin[0] <= 23 &&
        //   expoTokenAdmin[3] === expoAdmin) ||
        (expoAdmin != null &&
          dateCurrentAdmin[0] > 16 &&
          dateCurrentAdmin[0] <= 23 &&
          expoTokenAdmin[7] === expoAdmin)
      ) {
        sendPushNotification(expoAdmin, itemNo);
        // console.log(" here ... ", 3,expoAdmin);
      } else {
        // console.log("nothing ... ",itemNo);
      }
    });
  };

  const dateCurrentAdmin = new Date()
    .toLocaleTimeString("en-SA", {
      hour12: false,
    })
    .split(":");
  // React.useEffect(() => {
  //   console.log("dateCurrentAdmin: ", dateCurrentAdmin[0]);
  // }, []);

  const [values, setValues] = React.useState({
    ps: "",
    email: "",
    title: "",
    selectSex: "",
    socialState: "",
    workingCase: "",
    age: "",
    active: true,
    chooseType: "chat1999",
  });

  const handleChange = (prop) => (event) => {
    // console.log("token: ", token);
    setValues({ ...values, [prop]: event.target.value });
  };
  const kindSex = ["ذكر", "أنثى"];
  const kindSocialState = [
    "متزوج/متزوجة",
    "أعزب/عزباء",
    "أرمل/أرملة",
    "مطلق/مطلقه",
  ];
  const kindWorkingCase = ["موظف/موظفة", "غير موظف/غير موظفة"];
  // const kindSerivse = [
  //   {
  //     value: "chat1999",
  //     label: "شات",
  //   },
  //   {
  //     value: "verySpeed1799",
  //     label: "سريع جداََ",
  //   },
  //   {
  //     value: "speed1299",
  //     label: "سريع",
  //   },
  // ];
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  // const location = {
  //   pathname: "/successAddDream",
  //   state: { fromDashboard: true },
  // };

  // const { token } = useParams();

  const addNewDream = async (e) => {
    setLoading(true);

    e.preventDefault();

    // if (password === "") {
    //   setLoading(false);

    //   alert("Password cannot be empty");
    //   return;
    // }

    // if (password.length < 7) {
    //   setLoading(false);
    //   alert("Password is very small");
    //   return;
    // }

    // setPassword(password);
    // console.log("values: ",values);
    let result;
    try {
      instance
        .post(`/questions/create-question-by-email`, values)
        .then(({ data }) => {
          // console.log("data: ",  data , );

          if (typeof data == "string" && data.startsWith("you") === true) {
            result = alert("يوجد خطأ  في البريد أو الرقم السري");
          } else {
            // console.log("data: ",  data.itemNo);

            sendPushNotificationToAdmin(data.itemNo);
            result =
              setValues({
                email: "",
                ps: "",
                title: "",
                selectSex: "",
                socialState: "",
                workingCase: "",
                age: "",
                active: true,
                chooseType: "chat1999",
              }) & alert("تم إضافة الحلم بنجاح");

            // history.replace(location);
          }
          return result;
        });
      //   setPassword("");

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      console.clear();
      console.error("Sorry .. you can't access this page");
    }
  };
  return (
    <Container>
      {/* <Container component="main" maxWidth="xs"> */}
      <CssBaseline />
      <Button
        style={{
          color: "#5b3a70",
          fontSize: 15,
          // marginTop: 5,
          // marginBottom: 5,
          padding: 15,
        }}
        variant="outlined"
        fullWidth
        onClick={() => history.push(`/list`)}
      >
        {"لوحة التحكم"}
      </Button>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Edit />
        </Avatar>
        <Typography component="h1" variant="h5">
          اضف حلم جديد
        </Typography>

        {loading && <CircularProgress />}
        <form
          className={classes.form}
          noValidate
          onSubmit={addNewDream}
          id="formR"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            style={{ direction: "rtl" }}
            label="اكتب الإيميل"
            name="email"
            value={values.email}
            onChange={handleChange("email")}
            // autoComplete="password"
            // autoFocus
            // rows={10}
            // size={"medium"}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            style={{ direction: "rtl" }}
            label="الرقم السري"
            name="ps"
            value={values.ps}
            onChange={handleChange("ps")}
            // autoComplete="password"
            // autoFocus
            // rows={10}
            // size={"medium"}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            style={{ direction: "rtl" }}
            label="اكتب حلمك"
            name="title"
            value={values.title}
            onChange={handleChange("title")}
            // autoComplete="password"
            // autoFocus
            // rows={10}
            // size={"medium"}
            multiline
          />
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            style={{ direction: "rtl" }}
            Select
            label="نوع الجنس"
            name="نوع الجنس"
            value={selectSex}
            onChange={(e) => setSelectSex(e.target.value)}
            autoComplete="password"
            autoFocus
            // rows={10}
            // size={"medium"}
            // multiline
          /> */}
          <TextField
            // id="outlined-select-currency"
            select
            label="نوع الجنس"
            // name="sex"
            onChange={handleChange("selectSex")}
            fullWidth
            variant="outlined"
            margin="normal"
            required
            value={values.selectSex}
          >
            {kindSex.map((option, key) => (
              <MenuItem key={key} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            // id="outlined-select-currency"
            select
            label="اختر الحالة الاجتماعية"
            // name="socialState"
            onChange={handleChange("socialState")}
            fullWidth
            variant="outlined"
            margin="normal"
            required
            value={values.socialState}
          >
            {kindSocialState.map((option, key) => (
              <MenuItem key={key} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            style={{ direction: "rtl" }}
            label="اكتب حلمك"
            name="password"
            value={socialState}
            onChange={(e) => setSocialState(e.target.value)}
            autoComplete="password"
            autoFocus
            // rows={10}
            // size={"medium"}
            multiline
          /> */}

          <TextField
            // id="outlined-select-currency"
            select
            label="اختر العمر"
            // name="socialState"
            onChange={handleChange("age")}
            fullWidth
            variant="outlined"
            margin="normal"
            required
            value={values.age}
          >
            {[...Array(60).keys()]
              .filter((num) => num > 17)
              .map((option, key) => (
                <MenuItem key={key} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            // id="outlined-select-currency"
            select
            label="اختر الحالة المهنية"
            // name="socialState"
            onChange={handleChange("workingCase")}
            fullWidth
            variant="outlined"
            margin="normal"
            required
            value={values.workingCase}
          >
            {kindWorkingCase.map((option, key) => (
              <MenuItem key={key} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField
            // id="outlined-select-currency"
            select
            label="اختر نوع الخدمة؟"
            // name="socialState"
            onChange={handleChange("chooseType")}
            fullWidth
            variant="outlined"
            margin="normal"
            required
            value={values.chooseType}
          >
            {kindSerivse.map((option, key) => (
              <MenuItem key={key} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField> */}

          <Grid container spacing={1}>
            {/* <Grid item xs={10}> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              style={{ backgroundColor: "#5b3a70", color: "#ffffff" }}
            >
              إرسال
            </Button>
            {/* </Grid> */}
            {/* <Grid item xs={1} style={{alignSelf: "center"}}>
              <Tooltip title="يجب أن تكون كلمة المرور أكثر من ٦ أحرف أو أرقام">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default AddDream;
