import {useEffect, useRef, useContext} from "react";
import socketIOClient from "socket.io-client";
import {Context} from "../context/ContextProvider";
// import useSound from "use-sound";
// import boopSfx from "../assets/sounds/stories_sounds_pop-down.mp3";

const NEW_CHAT_MESSAGE_EVENT = "newChatMessage";
const TYPING = "typing";
const ENDPOINT = "https://chat-project-server.herokuapp.com/";
// const ENDPOINT = "http://localhost:8080/";

const useChat = (roomId) => {
  const {setComments, setIsTyping} = useContext(Context);
  const socketRef = useRef();
  // const [play] = useSound(boopSfx);

  useEffect(() => {
    socketRef.current = socketIOClient(ENDPOINT, {
      transports: ["websocket"],
      jsonp: false,
      query: {roomId},
    });
    socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
      // console.log("mes: ", message.body._id);
      // console.log("mes: ", message);
      // play();
      setComments((messages) => [...messages, message.body]);
    });

    socketRef.current.on(TYPING, (data) => {
      const currentUser = data.senderId === socketRef.current.id;
      if (!currentUser) {
        setIsTyping(data.isType);
      }
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);

  const sendMessage = (messageBody) => {
    // console.log("tes:", messageBody);
    // console.log("socket id:", socketRef.current.id);
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
      body: messageBody,
      senderId: socketRef.current.id,
    });
  };

  const typingUser = (text) => {
    const txt = text.trim();
    if (txt.length > 0) {
      socketRef.current.emit(TYPING, {
        isType: true,
        senderId: socketRef.current.id,
      });
    } else {
      socketRef.current.emit(TYPING, {
        isType: false,
        senderId: socketRef.current.id,
      });
    }
  };

  return {sendMessage, typingUser};
};

export default useChat;
