import React, {useEffect, useState, useContext} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Notifications from "@material-ui/icons/Notifications";
import HelpIcon from "@material-ui/icons/Help";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";
// import Alert from "@material-ui/lab/Alert";
import jwt_decode from "jwt-decode";
import instance from "../api/api";
import {Context} from "../context/ContextProvider";
import Loading from "../components/Item";

const useStyles = makeStyles((theme) => ({
  "@global": {
    password: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  avatar: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    top: 5,
    right: 5,
  },
}));

const Dashboard = () => {
  const {isAdmin, setIsAdmin, setIsLoading} = useContext(Context);

  const [unmounted, setUnmounted] = useState(true);
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newOject, setNewObject] = useState({
    email: "",
    password: "",
  });

  // const {
  //   // messages,
  //   // setMessages,
  //   // user,
  //   // setUser,
  // } = useContext(Context);

  useEffect(() => {
    if (isLogin) setUnmounted(true);
    return () => {
      setIsLogin(true);
    };
  }, [isLogin]);
  let history = useHistory();

  const classes = useStyles();
  const handleFields = (e) => {
    setNewObject({...newOject, [e.target.name]: e.target.value});
  };

  const sendNotification = async (e) => {
    e.preventDefault();
    setError("");

    const email = newOject.email;
    const password = newOject.password;
    if (email === "" || password === "") {
      alert("email/password cannot be empty");
      return;
    }

    setLoading(true);
    try {
      const req = await instance.post(`/users/login`, {
        email,
        password,
      });
      if (unmounted) {
        setIsLogin(false);
        setUnmounted(false);

        setNewObject({email, password});
        localStorage.setItem("Token", req.data.accessToken);
        var decoded = jwt_decode(req.data.accessToken);

        const isAdmin = decoded.user.admin;
        console.log("admin, ", isAdmin);

        // const isAdmin = true;
        setLoading(false);

        setIsLoading(true);

        setIsAdmin(isAdmin);

        history.push("/list");
      } else {
        setError("You can't access this page.");
      }
    } catch (error) {
      // console.log("jjj", error.message);
      setNewObject({email: "", password: ""});
      setLoading(false);
      setError(error.message);
      console.clear();
    }
  };

  if (isAdmin) {
    return <Loading />;
  }

  return (
    <Container component="main" maxWidth="lg">
      {/* {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )} */}

      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Login Admin
        </Typography>
        <Avatar className={classes.avatar}>
          <Notifications />
        </Avatar>

        <form
          className={classes.form}
          noValidate
          onSubmit={sendNotification}
          id="formR"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="email"
            name="email"
            value={newOject.email}
            onChange={(e) => handleFields(e)}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="password"
            name="password"
            value={newOject.password}
            onChange={(e) => handleFields(e)}
            autoComplete="password"
          />
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{background: "#5b3a70", color: "#ffffff"}}
                className={classes.submit}
              >
                {loading ? (
                  <CircularProgress style={{color: "#ffffff"}} size={20} />
                ) : (
                  "Login"
                )}
              </Button>
            </Grid>
            <Grid item xs={1} style={{alignSelf: "center", marginTop: 7}}>
              <Tooltip title="You need to setup your server before sending notifications. Check github repo for more info!">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default Dashboard;
