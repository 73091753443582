import React, {useState, useEffect} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Notifications from "@material-ui/icons/Notifications";
import HelpIcon from "@material-ui/icons/Help";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import instance from "../api/api";
// import axios from "axios";
import Table from "../components/table";

// const NOTIFICATIONS_SERVER = "http://localhost:5000/api/v1/push";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  avatar: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    top: 5,
    right: 5,
  },
}));

// axios.defaults.headers.post["Content-Type"] = "application/json";

const FormNotifcations = () => {
  const [fields, setFields] = useState([]);
  const [newOject, setNewObject] = useState({
    title: "",
    body: "",
    numberDevices: 0,
    created_at: "",
  });

  const fetchAllMessagePush = async () => {
    try {
      const getAllMessagePush = await instance.get(`/push/all-message-push`);

      const {data} = getAllMessagePush;
      setFields(data);
    } catch (error) {
      console.clear();
      console.error("Sorry .. you can't access this page");
    }
  };

  useEffect(() => {
    fetchAllMessagePush();
  }, []);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const handleFields = (e) => {
    setNewObject({...newOject, [e.target.name]: e.target.value});
  };

  const sendNotification = async (e) => {
    e.preventDefault();
    const title = newOject.title;
    const body = newOject.body;
    if (title === "" || body === "") {
      alert("title/body cannot be empty");
      return;
    }

    setLoading(true);
    const req = await instance.post(`/push/message`, {
      title,
      body,
    });
    setNewObject({
      ...newOject,
      numberDevices: req.data.numberDevices,
      created_at: new Date().getDate(),
    });
    const allFields = [newOject, ...fields];

    setFields(allFields);
    setLoading(false);
  };
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Notifications Tool
        </Typography>
        <Avatar className={classes.avatar}>
          <Notifications />
        </Avatar>

        {loading && <CircularProgress />}
        <form
          className={classes.form}
          noValidate
          onSubmit={sendNotification}
          id="formR"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Title"
            name="title"
            value={newOject.title}
            onChange={handleFields}
            autoComplete="title"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Body"
            name="body"
            value={newOject.body}
            onChange={handleFields}
            autoComplete="body"
          />
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{background: "#5b3a70", color: "#ffffff"}}
                className={classes.submit}
              >
                Send Notifications
              </Button>
            </Grid>
            <Grid item xs={1} style={{alignSelf: "center", marginTop: 7}}>
              <Tooltip title="You need to setup your server before sending notifications. Check github repo for more info!">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
      </div>
      <div className={classes.table}>
        <Typography variant="subtitle1">Last Notifications Sent:</Typography>
        <Table fields={fields} overflow="hidden" />
      </div>
    </Container>
  );
};
export default FormNotifcations;
