import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:5000/api/v1",
  baseURL:
    "https://us-central1-explication-dream.cloudfunctions.net/app/api/v1",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("Token"),
  },
});

instance.defaults.headers.post["Content-Type"] = "application/json";

export default instance;
