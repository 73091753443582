import * as React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Share from "@material-ui/icons/Share";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {

  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookShareButton,


} from "react-share";

// import axios from "axios";

// axios.defaults.headers.post["Content-Type"] = "application/json";

const shareUrl = "https://explication-dream.web.app/share-app/612966f7a90cbe2af3d918ce/61cfa8cede333f82e15f172d";
// const title = "GitHub";
const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#5b3a70",
    borderRadius: 5,
    color: "#ffffff",
    paddingTop: 3,
  },
  table: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  wrapperAvatar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent:'center',

  },
  avatar: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    backgroundColor: "#5b3a70",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    top: 5,
    right: 5,
  },
}));

const Promo = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h6" variant="h6">
          عدد المشاركات وصل 15 مشاركة
        </Typography>
      </div>
      <div className={classes.wrapperAvatar}>
        <Avatar
          className={classes.avatar}
          onClick={() => console.log("Click here .. ")}
        >
          <Share />
        </Avatar>
        </div>
        <div className={classes.wrapperAvatar}>

        <WhatsappShareButton url={shareUrl} style={{marginLeft:10}}>
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
        <FacebookShareButton url={shareUrl} style={{marginLeft:10}}>
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} style={{marginLeft:10}}>
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>
        <TelegramShareButton url={shareUrl} style={{marginLeft:10}}>
          <TelegramIcon size={40} round={true} />
        </TelegramShareButton>
     </div>
    </Container>
  );
};
export default Promo;
