import * as React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import instance from "../api/api";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

// {consultant.map((one, index) => (
//     // <React.Fragment>
//     <CardContent key={index}>
//       <Typography
//         sx={{ fontSize: 14 }}
//         color="text.secondary"
//         gutterBottom
//       >
//         {one.allQuestionsTodayHala.admin}
//       </Typography>
//       <Typography variant="h5" component="div">
//         عدد التفسيرات
//       </Typography>
//       <Typography sx={{ mb: 1.5 }} color="text.secondary">
//         {one.allQuestionsTodayHala.count}
//       </Typography>
//       <Typography variant="body2">
//         آخر ظهور الساعة - 11:10 ص
//       </Typography>
//     </CardContent>
//     // </React.Fragment>
//     ))}

// const card = (
//   <React.Fragment>
//     <CardContent>
//       <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
//         {/* {consultantList[0].admin} */} dd
//       </Typography>
//       <Typography variant="h5" component="div">
//         عدد التفسيرات
//       </Typography>
//       <Typography sx={{ mb: 1.5 }} color="text.secondary">
//         10
//       </Typography>
//       <Typography variant="body2">آخر ظهور الساعة - 11:10 ص</Typography>
//     </CardContent>
//     {/* <CardActions>
//         <Button size="small">Learn More</Button>
//         </CardActions> */}
//   </React.Fragment>
// );

export default function ConsultantsList() {
  const [consultantList, setConsultantList] = React.useState();
  const [newQuestion, setNewQuestion] = React.useState();
  const [allQuestion, setAllQuestion] = React.useState();
  const [lastSeen, setLastSeen] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const history = useHistory();

  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "مساءً" : "صباحاً";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };
  React.useEffect(() => {
    instance
      .get("/questions/consultants/list")
      .then(({ data }) => {
        setNewQuestion(data.newQuestion);
        setAllQuestion(data.allQuestion);
        setLastSeen(data.lastSeen);
        // console.log("data: ", data.lastSeen);
        setConsultantList(data.list);
        setIsLoading(false);
        // setTimeout(() => {
        //     console.log("editData: ", editData);
        // }, 3000);
      })
      .catch((error) => {
        console.log("error: ", error.message);
      });
  }, []);

  return (
    <Container>
      <Grid
      // container
      // direction="row"
      // justifyContent="space-around"
      // alignItems="flex-start"
      // style={{ margin: 20 }}
      >
        <Button
          style={{
            color: "#5b3a70",
            fontSize: 15,
            // marginTop: 5,
            marginBottom: 5,
            padding: 15,
          }}
          variant="outlined"
          fullWidth
          onClick={() => history.push(`/list`)}
        >
          {"لوحة التحكم"}
        </Button>
        {isLoading ? (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 100,
            }}
          >
            <CircularProgress style={{ color: "#5b3a70" }} />
          </Container>
        ) : (
          <>
            <Box sx={{ minWidth: 275, paddingBottom: 5 }}>
              <Card
                variant="outlined"
                style={{ backgroundColor: "#5b3a70", color: "#ffffff" }}
              >
                <React.Fragment>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} gutterBottom>
                      عدد طلبات اليوم : {allQuestion}
                    </Typography>
                    {/* <Typography sx={{ fontSize: 14 }} gutterBottom>
              {" "}
            </Typography> */}
                    {/* <Typography sx={{ mb: 1.5 }}>{" "}</Typography> */}
                    <Typography variant="h5" component="div">
                      عدد الطلبات الجديدة: {newQuestion}
                    </Typography>
                    <Typography variant="body2">
                      آخر دخول -{" "}
                      {lastSeen !== "00:00"
                        ? formatAMPM(new Date(lastSeen))
                        : "00:00"}
                    </Typography>
                  </CardContent>
                </React.Fragment>
              </Card>
            </Box>
            {consultantList.map((c, key) => {
              return (
                <Box sx={{ minWidth: 275, paddingBottom: 5 }} key={key}>
                  <Card variant="outlined" style={{ color: "#5b3a70" }}>
                    <React.Fragment>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} gutterBottom>
                          {c.admin}
                        </Typography>
                        <Typography variant="h5" component="div">
                          عدد التفسيرات: {c.count}
                        </Typography>
                        {/* <Typography sx={{ mb: 1.5 }}>{c.count}</Typography> */}
                        <Typography variant="body2">
                          آخر دخول -{" "}
                          {c.time !== "00:00"
                            ? formatAMPM(new Date(c.time))
                            : "00:00"}
                        </Typography>
                      </CardContent>
                    </React.Fragment>
                  </Card>
                </Box>
              );
            })}
          </>
        )}
      </Grid>
    </Container>
  );
}
