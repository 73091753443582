import React from "react";
// import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
// import jwt_decode from "jwt-decode";
// import { Context } from "../context/ContextProvider";
// import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // margin: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar({ t, i18n, changeLanguage }) {
  const classes = useStyles();
  // let history = useHistory();

  // const {
  //   user,
  //   setUser,
  //   isAdmin
  // } = useContext(Context);
  

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: "#5b3a70" }}>
        <Toolbar className={classes.menuButton}>
          <IconButton edge="start" color="inherit" aria-label="menu" href="/">
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title}>{""}</Typography>


          <Button
            onClick={() => window.open(`mailto:dreamslivechat@gmail.com`)}
            variant="text"
            color="inherit"
            size="small"
            className={classes.button}
          >
            {t("contactUs")}
          </Button>
          {/* {isAdmin  ? (
            <Button
              onClick={() => history.push("/list")}
              variant="text"
              color="inherit"
              size="small"
              className={classes.button}
            >
              List
            </Button>
          ) : null} */}
          <Button
            variant="text"
            color="inherit"
            size="small"
            className={classes.button}
            // href="/privacy"
            onClick={() =>
              i18n.language === "ar"
                ? changeLanguage("en")
                : changeLanguage("ar")
            }
          >
            {i18n.language === "ar" ? "English" : "العربية"}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
