import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// import ReactStoreBadges from "react-store-badges";
import StoreBadge from 'react-store-badge';

// import FingerprintJS from "@fingerprintjs/fingerprintjs";
// import instance from "../api/api";

// const fpPromise = FingerprintJS.load();

// (async () => {
//   // Get the visitor identifier when you need it.
//   const fp = await fpPromise;
//   const result = await fp.get();

//   // This is the visitor identifier:
//   const visitorId = result.visitorId;

//   console.log(visitorId);
//   instance
//     .post(
//       "/users/share-app/61053d40d451f43175e0e111/61058e47f48c9466a3209bd8",
//       {visitorId}
//     )
//     .then(() => console.log("save data ..."))
//     .catch((e) => console.log("error: ", e.message));
// })();

// Print the 32bit hash id to the console

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 5,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Home({ t, i18n }) {
  const classes = useStyles();
  // useEffect(() => {
  //   console.log("finger: ", fingerprint);
  // }, []);

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h6" component="p" style={{ marginTop: 2 }}>
            {t("appName")}
          </Typography>
          <Typography variant="body2" component="p" style={{ marginTop: 5 }}>
            {t("descriptionApp")}.
          </Typography>
          <Typography variant="h6" style={{ marginTop: 10 }}>
            {t("appContent")}:
          </Typography>
          <Typography variant="body2">- {t("privately")}.</Typography>
          <Typography variant="body2">- {t("liveChat")}.</Typography>
          {/* <Typography variant="body2">- {t("laterPayment")}.</Typography> */}
          <Typography variant="body2" style={{ marginBottom: 0 }}>
            - {t("removeDream")}.
          </Typography>
        </CardContent>
        {/* <CardContent align="center">
          <ReactStoreBadges
            platform={"ios"}
            url={"https://apps.apple.com/app/id1574941244"}
            locale={"en-us"}
          />{" "}
          <ReactStoreBadges
            platform={"android"}
            url={
              "https://play.google.com/store/apps/details?id=com.adeb1991.explicationyourdream"
            }
            locale={"en-us"}
          />
        </CardContent> */}
        <CardContent align="center">
          <StoreBadge
            name="تفسير الاحلام الشات المباشر"
            googlePlayUrl="https://play.google.com/store/apps/details?id=com.adeb1991.explicationyourdream"
            appStoreUrl="https://apps.apple.com/app/id1574941244"
          />
        </CardContent>
      </Card>
    </>
  );
}
