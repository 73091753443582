import React, { createContext, useState } from "react";

export const Context = createContext({});
export const ContextProvider = ({ children }) => {
  const [roomId, setRoomId] = useState("");
  const [displayNameUser, setDisplayNameUser] = useState("Customer");

  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDream, setIsShowDream] = useState(true);
  const [comments, setComments] = useState([]);
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [user, setUser] = useState(null);
  const [expoTokenAdmin, setExpoTokenAdmin] = useState([]);

  const value = {
    isAdmin,
    setIsAdmin,
    name,
    room,
    setName,
    setRoom,
    // users,
    // setUsers,
    roomId,
    setRoomId,
    messages,
    setMessages,
    isTyping,
    setIsTyping,
    comments,
    setComments,
    data,
    setData,
    user,
    setUser,
    isLoading,
    setIsLoading,
    displayNameUser,
    setDisplayNameUser,
    isShowDream,
    setIsShowDream,
    expoTokenAdmin, 
    setExpoTokenAdmin
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
