import React, { useState, useContext } from "react";
import { Context } from "../context/ContextProvider";
import Container from "@material-ui/core/Container";
// import { CircularProgress, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import instance from "../api/api";
export default function Item() {
  let history = useHistory();
  const [text, setText] = useState("");
  const {
    // comments,
    // setComments,
    // data,
    // setData,
    // isTyping,
    // displayNameUser,
    // setDisplayNameUser,
    // user,
    // setUser,
    setIsShowDream,
  } = useContext(Context);
  const goOrder = () => {
    if (text.length === 0 || isNaN(text)) {
      alert("نرجو كتابة رقم طلب صحيح");
      setText("");
      return;
    }
    instance
      .get(`/questions/getOneQuestion/${text}`)
      .then(({ data }) => {
        // console.log("data: ", data);
        setIsShowDream(data.isReading);
        // setEditData(data);
        // setTimeout(() => {
        //     console.log("editData: ", editData);
        // }, 3000);

        setText("");
        history.push(`/onequestion/${data._id}`);
      })
      .catch((error) => {
        console.log("error: ", error.message);
        if (error.message.startsWith("Cannot read")) {
          alert("رقم الطلب غير صحيح يا أمورة");
        }
      });
  };

  return (
    <Container
      style={{
        // display: "flex",
        // justifyContent: "flex-start",
        // marginTop: 100,
        // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
      }}
    >
      <Button
        style={{
          color: "#5b3a70",
          fontSize: 15,
          marginBottom: 5,
          padding: 15,
        }}
        variant="outlined"
        fullWidth
        onClick={() => history.push(`/list`)}
      >
        {"لوحة التحكم"}
      </Button>
      {/* <Typography>تواصل مع إدارة الموقع</Typography> */}
      {/* <CircularProgress style={{ color: "#5b3a70" }} size={20} ml={20} /> */}
      <div style={{ width: "100%" }}>
        <TextField
          placeholder="رقم الطلب"
          // multiline
          // id="text"
          // type="text"
          mb={10}
          // size="medium"
          variant="outlined"
          margin="none"
          // required
          // marginBottom: 5,

          fullWidth
          onChange={(e) => {
            // if (e.target.value.length > 0) {
            //   typingUser(e.target.value);
            // } else {
            //   typingUser("");
            // }
            setText(e.target.value);
          }}
          value={text}
          onClick={() => {
            setText("");
          }}
          style={{marginBottom: 5,}}
        />
        <Button
          onClick={goOrder}
          fullWidth
          mb={10}
          margin="normal"
          variant="contained"
          style={{ background: "#5b3a70", color: "#ffffff", height: 50 }}
          // className={classes.submit}
        >
          بحث
        </Button>
      </div>
    </Container>
  );
}
