import * as React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Done from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

// import axios from "axios";

// axios.defaults.headers.post["Content-Type"] = "application/json";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(30),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  avatar: {
    marginBottom: theme.spacing(5),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    top: 5,
    right: 5,
  },
}));

const FormResetPassword = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Done />
        </Avatar>
        <Typography component="h6" variant="h6">
          تم إعادة كلمة المرور بنجاح
        </Typography>
      </div>
    </Container>
  );
};
export default FormResetPassword;
