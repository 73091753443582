import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import instance from "../api/api";
import { Context } from "../context/ContextProvider";
import { Button } from "@material-ui/core";
export default function List() {
  let history = useHistory();
  const { setExpoTokenAdmin } = useContext(Context);

  React.useEffect(() => {
    const fetchDateExpo = async () => {
      try {
        const { data } = await instance.get("/users/admin-users");
        // console.log("expo data list : ", data);

        // const {expoTokenAdminUsers} = await dataExpo.data;
        setExpoTokenAdmin(data);
        // console.log("expo token admin:  ", expoTokenAdmin);
        // return expoTokenAdminUsers;
      } catch (error) {
        console.log("error data admin: ", error.message);
        // return;
      }
    };
    fetchDateExpo();
  },[]);
  return (
    <Container>
      {/* style={{ display: "flex", justifyContent: "center", marginTop: 0 }} */}

      <Grid
      // direction="row"
      // justifyContent="space-around"
      // alignItems="flex-start"
      // style={{ margin: 20 }}
      >
        <Button
          style={{
            color: "#5b3a70",
            fontSize: 15,
            // marginTop: 5,
            marginBottom: 5,
            padding: 15,
          }}
          variant="outlined"
          fullWidth
          onClick={() => history.push(`/add-dream`)}
        >
          {"إضافة حلم جديد"}
        </Button>
        <Button
          style={{
            color: "#5b3a70",
            fontSize: 15,
            // marginTop: 5,
            marginBottom: 5,
            padding: 15,
          }}
          variant="outlined"
          fullWidth
          onClick={() => history.push(`/orders`)}
        >
          {"صفحة الطلبات"}
        </Button>
        <Button
          style={{
            color: "#5b3a70",
            fontSize: 15,
            // marginTop: 5,
            marginBottom: 5,
            padding: 15,
          }}
          variant="outlined"
          fullWidth
          onClick={() => history.push(`/item`)}
        >
          {"متابعة طلب"}
        </Button>
        <Button
          style={{
            color: "#5b3a70",
            fontSize: 15,
            // marginTop: 5,
            marginBottom: 5,
            padding: 15,
          }}
          variant="outlined"
          fullWidth
          onClick={() => history.push(`/consultants-list`)}
        >
          {"صفحة المفسريين"}
        </Button>
      </Grid>
    </Container>
  );
}
